<template>
  <div>
    <b-card-code title="Add Currency">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col md="6">
              <b-form-group label="Name" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="currencyData.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Currency Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Code -->
            <b-col md="6">
              <b-form-group label="Code" label-for="code">
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:3"
                  name="code"
                >
                  <b-form-input
                    v-model="currencyData.code"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Min 3 Char"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--Number-->
            <b-col md="6">
              <b-form-group label="Number" label-for="number">
                <validation-provider
                  #default="{ errors }"
                  rules="required|digits:3"
                  name="number"
                >
                  <b-form-input
                    v-model="currencyData.number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Number Only"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Locale -->
            <b-col md="6">
              <b-form-group label="Locale" label-for="locale">
                <validation-provider
                  #default="{ errors }"
                  name="locale"
                  rules="required"
                >
                  <b-form-input
                    id="locale"
                    v-model="currencyData.locale"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Locale"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'

import { required, integer } from '@validations'
export default {
  components: {
    BCardCode,
  },
  
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      currencyData: {
        name: '',
        code: '',
        number: '',
        locale: '',
      },
      errors_back: [],
      required,
      integer,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .post('currencies', this.currencyData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push('/Currencies')
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">

</style>
